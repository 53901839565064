import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Divider,
  Fab,
  InputAdornment,
  darken,
  Backdrop,
  ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import moment from "moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Feedback from "../../atoms/Feedback";
import { getCurrencies } from "../../../actions/currencyActions";
import { getCostCodesByType } from "../../../actions/costCodeAction";
import { formatAmount } from "../../../utils";
// import { currencyConvert } from "../../../utils/currencyconvert";
import { getAllMonthlyBudgetItems } from "../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines } from "../../../actions/annualAction";

import { getDivisions } from '../../../actions/divisionActions';
import {
  submitCashAdvance,
  getAllCashAdvances,
  getCashAdvanceById,
  updateCashAdvance,
} from "../../../actions/cashAdvanceAction";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { getCode, getSymbol } from "../../../utils/currencyconvert";
import SubmitCashAdvanceModal from "./modals/SubmitCashAdvanceModal";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  total: {
    marginTop: 40,
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  submitBtn: {
    // margin: "30px 0",
    // padding: theme.spacing(2),
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 300,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  status: {
    width: 140,
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  textGreen: {
    color: "#388e3c",

    fontSize: 11,
    fontWeight: 500,
  },
  centered: {
    textAlign: "center",
  },
  paperBlack: {
    color: "#f5f5f5",
    // background: "#3c3737",
    background: theme.palette.grey.A400,
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
    textAlign: "center",
    maxWidth: 400,
    marginLeft: "auto",
    marginRight: "auto",
  },
  info: {
    marginBottom: 20,
    fontWeight: 500,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },

  audit: {
    padding: 10,
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    // fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditTitle: {
    padding: 10,
    // background: theme.palette.grey[600],
    color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
    fontFamily: "Rubik",
    fontSize: 10,
    fontWeight: "bold",
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditContainer: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const INIT_STATE = {
  employeeName: "",
  employeeId: "",
  division: "",
  month: moment().format("MMMM"),
  year: moment().format("YYYY"),
  purpose: "",
  advanceType:"",
  costType: "",
  costCenter: "",
  serviceDivision: null,
  rate: 1,
  items: [
    {
      item: undefined,
      amount: 0,
      description: "",
    },
  ],
  status: "",
  total: 0,
  currency: "NGN",
  convertedTotal: 0,
};

const INIT_BREAKDOWN = {
  item: undefined,
  amount: 0,
  description: "",
};

const CashAdvanceEdit = ({
  getCurrencies,
  currencies,
  getCostCodesByType,
  costcodes,
  getAllMonthlyBudgetItems,
  getDivisionYearAnnualBudgetLines, 
  profile,
  getDivisions,
  divisions,
  annualBudgetLines,
  items,
  updateCashAdvance,
  getAllCashAdvances,
  submitCashAdvance,
  getCashAdvanceById,
  cashAdvance,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();

  const [state, setState] = useState(INIT_STATE);
  const [total, setTotal] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  const [openSubmit, setOpenSubmit] = useState(false);

  const [breakdown, setBreakdown] = useState({
    lists: [INIT_BREAKDOWN],
  });

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const getAllMonthlyBudgetItemsCallback = useCallback(() => {
    setLoad((prev) => !prev);
    let year = cashAdvance && cashAdvance.year;
    let month = cashAdvance && cashAdvance.month;
    let serviceDivision = cashAdvance && cashAdvance.advanceDivision;
    let costType = cashAdvance && cashAdvance.costType;
    let data = {
      year,
      month,
      serviceDivision,
      costType,
    };
    if (year !== undefined || month !== undefined) {
      getAllMonthlyBudgetItems(data).then(() => {
        setLoad(false);
      });
    }
  }, [getAllMonthlyBudgetItems, cashAdvance]);

  useEffect(() => {
    const abort = new AbortController();
    getAllMonthlyBudgetItemsCallback();
    return () => {
      abort.abort();
    };
  }, [getAllMonthlyBudgetItemsCallback]);
  
  const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
    setLoad((prev) => !prev);
    let data = {
      year: cashAdvance && cashAdvance.year,
      division: cashAdvance && cashAdvance?.advanceDivision?._id,
    };
    if (data.year !== undefined || data.division !== undefined) {
      getDivisionYearAnnualBudgetLines(data).then(() => {
        setLoad(false);
      });
    }
  }, [getDivisionYearAnnualBudgetLines, cashAdvance]);

  useEffect(() => {
    const abort = new AbortController();
    getDivisionYearAnnualBudgetLinesCallback();
    return () => {
      abort.abort();
    };
  }, [getDivisionYearAnnualBudgetLinesCallback]);



  const getCashAdvanceByIdCallback = useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getCashAdvanceById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getCashAdvanceById, id]);

  useEffect(() => {
    getCashAdvanceByIdCallback();
  }, [getCashAdvanceByIdCallback]);

  useEffect(() => {
    getDivisions();
  }, [getDivisions]);

  useEffect(() => {
    setBreakdown({
      lists: cashAdvance && cashAdvance.items,
    });
  }, [cashAdvance]);

  useEffect(() => {
    let total =
      breakdown.lists &&
      breakdown.lists.reduce((acc, cur) => {
        return acc + +cur.amount;
      }, 0);
    setTotal(total);
  }, [breakdown, state.rate]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: cashAdvance && cashAdvance.employee,
      employeeId: cashAdvance && cashAdvance.employeeId,
      division:
        cashAdvance && cashAdvance.division && cashAdvance.division.name,
      month: cashAdvance && cashAdvance.month,
      year: cashAdvance && cashAdvance.year && cashAdvance.year.toString(),
      purpose: cashAdvance && cashAdvance.purpose,
      costType: cashAdvance && cashAdvance.costType,
      costCenter: cashAdvance && cashAdvance.costCenter,
      rate: (cashAdvance && cashAdvance.rate) || 1,
      advanceDivision: (cashAdvance && cashAdvance.advanceDivision) || null,
      serviceDivision: (cashAdvance && cashAdvance.advanceDivision) || null,
      advanceType:  (cashAdvance && cashAdvance.advanceType) || null,
      status: cashAdvance && cashAdvance.status,
      currency: (cashAdvance && cashAdvance.currency) || "NGN",
      convertedTotal: cashAdvance && cashAdvance.convertedTotal,

      createdBy: cashAdvance && cashAdvance.createdBy,
      createdAt: cashAdvance && cashAdvance.createdAt,

      declinedBy: cashAdvance && cashAdvance.declinedBy,
      declinedAt: cashAdvance && cashAdvance.declinedAt,
      declineComment: cashAdvance && cashAdvance.declineComment,

      rejectedBy: cashAdvance && cashAdvance.rejectedBy,
      rejectedAt: cashAdvance && cashAdvance.rejectedAt,
      rejectComment: cashAdvance && cashAdvance.rejectComment,

      retireDeclinedBy: cashAdvance && cashAdvance.retirement?.declinedBy,
      retireDeclinedAt: cashAdvance && cashAdvance.retirement?.declinedAt,
      retireDeclineComment: cashAdvance && cashAdvance.retirement?.declineComment,

      retireRejectedBy: cashAdvance && cashAdvance.retirement?.rejectedBy,
      retireRejectedAt: cashAdvance && cashAdvance.retirement?.rejectedAt,
      retireRejectComment: cashAdvance && cashAdvance.retirement?.rejectComment,

    }));
  }, [cashAdvance]);

  // console.log(cashAdvance)

  useEffect(() => {
    setLoad((prev) => !prev);
    getCurrencies().then(() => {
      setLoad(false);
    });
  }, [getCurrencies]);

  const codeTypeCallback = useCallback(() => {
    setLoad((prev) => !prev);
    if (state.costType !== "") {
      getCostCodesByType(state.costType).then(() => {
        setLoad(false);
      });
    }
  }, [getCostCodesByType, state]);

  // const codeCallback = useCallback(() => {
  //   if (currencies !== undefined || state.rate !== undefined) {
  //     let code = getCode("NGN", currencies);
  //     setCode(code);
  //   }
  // }, [currencies, state.rate]);

  // useEffect(() => {
  //   codeCallback();
  // }, [codeCallback]);

  // console.log(code);

  useEffect(() => {
    codeTypeCallback();
  }, [codeTypeCallback]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChaneBreakdown = (i) => (e) => {
    e.persist();
    const newBreakdown = breakdown.lists.map((item, index) => {
      if (i !== index) {
        return item;
      }

      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setBreakdown({
      lists: newBreakdown,
    });
  };

  const isServices = profile ? profile.division && (profile.division.name === "Services" ||
    profile.division.name === "Procurement Management") : false;

  let code = getCode(state.rate, currencies);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    setOpenBackdrop((prev) => !prev);
    let items = breakdown.lists.map((item) => {
      let amount;
      if (item.amount) {
        amount = +item.amount;
      }
      return {
        ...item,
        amount: amount,
      };
    });
    const data = {
      year: state.year,
      month: state.month,
      purpose: state.purpose,
      costCenter: state.costCenter,
      costType: state.costType,
      advanceDivision: state.serviceDivision,
      total: total,
      rate: state.rate,
      currency: code,

      items,
      id: id,
    };

    try {
      const res = await new Promise((resolve) => {
        resolve(updateCashAdvance(data));
      });
      const doc = res;
      if (doc) {
        setLoading(false);
        setOpenBackdrop(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Cash Advance Updated Successfully`,
          success: true,
        });

        getCashAdvanceById(id);
      }
    } catch (err) {
      setLoading(false);
      setOpenBackdrop(false);

      setFeed({
        loading: false,
        open: !feed.open,
        message: err.message,
        success: false,
      });
    }
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleAddBreakdown = () => {
    let data = {
      item: undefined,
      amount: 0,
      description: "",
    };
    setBreakdown({
      lists: breakdown.lists.concat([data]),
    });
  };
  const handleRemoveBreakdown = (i) => {
    setBreakdown((prev) => ({
      ...prev,
      lists: prev.lists.filter((_, index) => index !== i),
    }));
  };

  // console.log(state.currency);

  const handleOpenSubmit = () => {
    setOpenSubmit(true);
  };

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  return (
    <div>
      <SubmitCashAdvanceModal
        {...{
          openSubmit,
          handleCloseSubmit,
          id,
        }}
      />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <div className={classes.container}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{ marginBottom: 30 }}
        >
          <Grid item>
            <Typography variant="overline" className={classes.title}>
              Cash Advance Details Form
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.goBack()}
              startIcon={<ArrowBackIosIcon />}
              className={classes.createBtn}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Paper
              variant="outlined"
              className={clsx(
                state.status === "draft"
                  ? classes.statusYellow
                  : state.status === "submitted" || state.status === "tomodify"
                    ? classes.statusOrange
                    : state.status === "approved" || state.status === "confirmed"
                      ? classes.statusGreen
                      : state.status === "completed" || state.status === "reviewed"
                        ? classes.statusDarkGreen
                        : classes.statusRed,
                classes.status
              )}
            >
              <Typography variant="overline" className={classes.statusTitle}>
                Status
              </Typography>
              <Divider className={classes.divider2} orientation="vertical" />
              {openBackdrop
                ? "Loading..."
                : state.status && state.status.toUpperCase()}
            </Paper>
          </Grid>
        </Grid>

        <form component={Paper} onSubmit={handleSubmit}>
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                label="Employee Name"
                name="employeeName"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.employeeName || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="EID"
                name="employeeId"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.employeeId || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Division/Subdivision"
                name="division"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.division || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Month"
                name="month"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.month || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Year"
                name="year"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.year || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Purpose of Advance"
                name="purpose"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.purpose || ""}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="costTypeId">Cost Type</InputLabel>
                <Select
                  labelId="costTypeId"
                  name="costType"
                  inputProps={{
                    id: "costType",
                    name: "costType",
                  }}
                  onChange={handleChange}
                  value={state.costType || ""}
                >
                  <MenuItem value="project">Project</MenuItem>

                  <MenuItem value="division">Division/Subdivision</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="costCodeId">Cost Center</InputLabel>
                <Select
                  labelId="costCodeId"
                  name="costCenter"
                  inputProps={{
                    id: "costCenter",
                    name: "costCenter",
                  }}
                  onChange={handleChange}
                  value={state.costCenter || ""}
                  disabled={!state.costType || load}
                >
                  <MenuItem value="Solutions" disabled>
                    <em>---SELECT COST CENTER----</em>
                  </MenuItem>
                  {costcodes &&
                    costcodes.map((cost) => (
                      <MenuItem key={cost._id} value={cost.name}>
                        {cost.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {(isServices) ? (
              <>
                <Grid item xs={12} md={12}>
                  <Typography variant="overline" className={classes.title}>
                    Cash Advance Division:  Only select this field if you are raising cash advance for another subdivision
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="serviceDivision">Cash Advance Division</InputLabel>
                    <Select
                      labelId="serviceDivision"
                      name="serviceDivision"
                      inputProps={{
                        id: "serviceDivision",
                        name: "serviceDivision",
                      }}
                      onChange={handleChange}
                      value={state.serviceDivision || ""}
                    // disabled={!isServices}
                    >
                      <MenuItem value="" disabled>
                        <em>---SELECT CASH ADVANCE DIVISION ----</em>
                      </MenuItem>
                      {divisions &&
                        divisions.map((sub) => (
                          <MenuItem key={sub._id} value={sub._id}>
                            {sub.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </>
            ) : null}


            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>

            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="overline" className={classes.title2}>
                  Advance breakdown
                </Typography>
              </Grid>
              <Grid item style={{ marginRight: 10 }}>
                <FormControl style={{ width: 130 }} variant="outlined" required>
                  <InputLabel id="currencyId">Currency</InputLabel>
                  <Select
                    labelId="currencyId"
                    name="rate"
                    inputProps={{
                      id: "rate",
                      name: "rate",
                    }}
                    value={state.rate || 1}
                    onChange={handleChange}
                  >
                    <MenuItem value={1} disabled>
                      <em>NGN</em>
                    </MenuItem>
                    {currencies &&
                      currencies.map((currency) => (
                        <MenuItem key={currency._id} value={currency.rate}>
                          {currency.code}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {openBackdrop ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={300}
                // animation="wave"
                style={{
                  background: "#ffc1071f",
                  padding: 10,
                  borderRadius: 3,
                  marginTop: 20,
                }}
              />
            ) : (
              breakdown &&
              breakdown.lists &&
              breakdown.lists.map((item, i) => (
                <Grid
                  container
                  spacing={3}
                  key={i}
                  justify="center"
                  alignItems="center"
                  style={{ marginRight: 10, marginTop: 10, marginLeft: 5 }}
                >
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="itemId">Expense Category</InputLabel>
                      <Select
                        labelId="itemId"
                        name="item"
                        inputProps={{
                          id: "item",
                          name: "item",
                        }}
                        value={(item.item && item.item._id) || item.item}
                        onChange={handleChaneBreakdown(i)}
                        disabled={openBackdrop}
                      >
                        <MenuItem disabled value="Internet Subscription">
                          <em>---SELECT ANNUAL BUDGET EXPENSE CATEGORY----</em>
                        </MenuItem>
                        {annualBudgetLines &&
                          annualBudgetLines.map((row) => (
                            <MenuItem key={row?.item?._id} value={row?.item?._id}>
                              {row?.item?.name} {" - "} {formatAmount(row?.subTotal) || ""}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Amount"
                      name="amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={item.amount}
                      onChange={handleChaneBreakdown(i)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Description"
                      name="description"
                      variant="outlined"
                      fullWidth
                      value={item.description || ""}
                      onChange={handleChaneBreakdown(i)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Tooltip arrow title="Remove">
                      <Fab
                        size="small"
                        color="secondary"
                        onClick={() => handleRemoveBreakdown(i)}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))
            )}
            <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
              <Button
                variant="outlined"
                color="default"
                onClick={() => handleAddBreakdown(breakdown.length + 1)}
                endIcon={<AddIcon fontSize="large" />}
                style={{ marginLeft: 20 }}
              >
                Insert Item
              </Button>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <TextField
                label="Total Amount Required"
                variant="outlined"
                fullWidth
                value={formatAmount(total || 0) || 0}
                disabled
                className={classes.total}
                InputProps={{
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        {/* &#8358; */}
                        {getSymbol(code, total)}
                      </InputAdornment>
                      <Divider
                        className={classes.divider2}
                        orientation="vertical"
                      />
                    </>
                  ),
                }}
                helperText={
                  <span className={classes.textGreen}>
                    Naira Equivalent: NGN{" "}
                    {formatAmount(state.convertedTotal || 0)}
                  </span>
                }
              />
            </Grid>
          </Grid>


        {/* Audit trail */}
        {(state.status === "draft" || state.status === "rejected" || state.status === "declined") ? (
        <>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            spacing={1}
            style={{ marginBottom: 5 }}
          >
            <Grid item>
              <Typography variant="overline" className={classes.title2}>
                Audit Trail
              </Typography>
            </Grid>
          </Grid>
          <Paper variant="outlined" square className={classes.paper}>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Submitted By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.createdBy?.userName}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.createdAt}</span>
                </Paper>
              </Grid>

              {state.declinedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Declined By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.declinedBy?.userName || ""}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.declinedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.declineComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.rejectedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Rejected By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.rejectedBy?.userName || ""}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.rejectedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.rejectComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.retireDeclinedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Retirement Declined By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireDeclinedBy?.userName}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireDeclinedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireDeclineComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.retireRejectedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Retirement Rejected By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireRejectedBy?.userName}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireRejectedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireRejectComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              </Grid>
            </Paper>
          </>
        ) : null}





          <div className={classes.centered}>
            <Paper className={classes.paperBlack}>
              <HelpOutlineIcon fontSize="large" />
              <Typography placeholder="Info" className={classes.info}>
                After Editing this form, kindly save before you submit.
              </Typography>
            </Paper>
          </div>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <ButtonGroup>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={loading || load}
                  className={classes.saveBtn}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>

                <Button
                  onClick={handleOpenSubmit}
                  size="large"
                  variant="contained"
                  className={classes.submitBtn}
                >
                  {"Submit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </form>
      </div>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currencies: state.currency.currencies,
  costcodes: state.costcode.costcodes && state.costcode.costcodes.data,
  cashAdvance: state.cashAdvance.cashAdvance,
  profile: state.user.profile,
  items: state.monthlyBudget.items,
  annualBudgetLines: state.annualBudget.annualBudgetLines,
  divisions: state.division.divisions,
  // error: state.cashAdvance.cashAdvanceError
});

const mapDispatchToProps = {
  getCostCodesByType,
  getCurrencies,
  getAllMonthlyBudgetItems,
  getDivisionYearAnnualBudgetLines,
  updateCashAdvance,
  getAllCashAdvances,
  getCashAdvanceById,
  submitCashAdvance,
  getDivisions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashAdvanceEdit);
