import React from "react";
import {
  Typography,
  makeStyles,
  Paper,
  Grid,
  Card,
  CardContent,
  Divider,
  darken,
  // Avatar,
  Button,
  Icon,
  // CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
// import { formatCurrency } from "../../../utils";
// import { PieChart } from "./Charts";
import russelsmithgrp from '../../../assets/russelsmithgrp_logo.jpg';
import avatar from "../../../assets/profile_av.jpg";

// import PurchaseTable from "./PurchaseTable";
import WelcomeFeedback from "../../atoms/WelcomeFeedback";
import Feedback from "../../atoms/Feedback";

import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import Skeleton from "@material-ui/lab/Skeleton";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import moment from "moment";
import {
  getTotalAnnualBudget,
  getTotalMonthlyBudget,
  getTotalSupplementaryBudget,
} from "../../../actions/analyticsActions";
import { getCount } from "../../../actions/countActions";

import { getNotifications, deleteNotification, } from "../../../actions/notificationActions";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Quicksand",
  },
  analytics: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 18,
    // color: darken(theme.palette.common.white, 0.3),
    letterSpacing: 2.5,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    maxWidth: 600,
    "&:after": {
      content: "''",
      position: "absolute",
      width: 70,
      borderRadius: 5,
      background: "#ccc",
      height: 2,
      flex: 1,
      display: "block",
      top: "45%",
      left: "102%",
      [theme.breakpoints.down("sm")]: {
        width: 50,
      },
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: 5,
      borderRadius: 5,
      background: "#ccc",
      height: 2,

      flex: 1,
      display: "block",
      top: "45%",
      right: "102%",
      [theme.breakpoints.down("sm")]: {
        width: 3,
      },
    },
  },
  overview: {
    // color: darken(theme.palette.common.white, 0.3),
    textTransform: "uppercase",
    fontWeight: 400,
    letterSpacing: 2.5,
  },
  date: {
    // marginLeft: 10,
    padding: 10,
    // background: "#07a4ace3",
    // color: "#fff",
    fontWeight: 900,
    fontSize: 12,
  },
  paper: {
    marginTop: 30,
  },
  gridContainer: {
    // width: "100%",
    // padding: theme.spacing(4),
    marginTop: 30,
  },
  gridContainer2: {
    // padding: theme.spacing(4),
    marginTop: 10,
  },
  amount: {
    color: darken(theme.palette.common.white, 0.5),
  },
  amountGradient: {
    color: "#ffffff",
    fontWeight: 900,
  },
  infoText: {
    fontWeight: 600,
    color: darken(theme.palette.common.white, 0.3),
    letterSpacing: 2.5,
    fontSize: 11,
  },
  wrapper: {
    marginTop: 30,
  },
  card: {
    // width: "100%",
    textAlign: "center",
    padding: theme.spacing(4),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  time: {
    marginLeft: 5,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 900,
    fontSize: 12,
    maxWidth: 150,
  },

  helloText: {
    color: "#bdbdbd",
    fontSize: "1.75rem",
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.45rem",
    },
  },

  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  currency: {
    fontFamily: "Rubik",
    fontWeight: 400,
    lineBreak: "anywhere",
    lineHeight: 1,
    fontSize: 18,

    maxWidth: 250,
  },
  ship: {
    width: 70,
    height: 70,
    textAlign:'center',
    margin: 'auto',
    display:'block',

  },
  zeroContainer: {
    padding: theme.spacing(0.5),
  },
  zeroNotify: {
    display: "block",
    margin: "10px 0",
    textAlign:'center',
    letterSpacing: 2.5,
    fontSize: 14,
    lineHeight: 1.7,
  },
  icons: {
    width: 50,
    height: 50,
    padding: 10,
    borderRadius: 50,
    border: "5px solid #ccc",
  },
  cardTitle: {
    // padding: 16,
    letterSpacing: 2.5,
  },
  cardTitle2: {
    color: "rgb(82 46 39 / 81%)",
    letterSpacing: 8.5,
    fontWeight: 900,
    fontSize: 32,
    display: "block",
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 27,
    },
  },
  cardTitle3: {
    // color: "#333",
    letterSpacing: 2.5,
    fontSize: 33,
    fontWeight: 300,
    display: "block",
    lineHeight: 1.5,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  cardContent32: {
    padding: 16,
    overflow: "hidden",
    background: "rgb(255 193 7 / 82%)",
    position: "relative",
    backgroundImage: "url(/img/pie-chart.svg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right -50px top -10px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      backgroundPosition: "right -100px top -10px",
    },
  },
  cardContent: {
    overflow: "auto",
    padding: 16,
  },
  cardContentOverflow: {
    overflow: "hidden",
    padding: 10,
    boxShadow: '4px 8px 6px -6px #222',
  },
  cardContent2: {
    padding: 0,
    overflow: "auto",

    // background: "rgb(255 193 7 / 82%)",
    // height: 350,
    position: "relative",
    // backgroundImage: "url(/img/pie-chart.svg)",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "contain",
    // backgroundPosition: "right -50px top -10px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      backgroundPosition: "right -100px top -10px",
    },
  },
  chartIcon: {
    position: "absolute",
    top: 20,
  },
  btn: {
    padding: theme.spacing(2),
    letterSpacing: 1,
    minWidth:180,
    paddingLeft: 10,
    paddingRight: 10,
  },
  left: {
    textAlign: "right",
    display: "block",
    fontSize: 12,
    fontStyle: "italic",
    fontFamily: "Carter One",
  },
}));

// const colors = [
//   "hsl(133, 70%, 50%)",
//   "hsl(77, 70%, 50%)",
//   "hsl(103, 70%, 50%)",
//   "hsl(70, 70%, 50%)",
//   "hsl(133, 70%, 50%)",
//   "hsl(133, 70%, 50%)",
//   "hsl(132.5, 54.54545454545454%, 25.882352941176475%)",
//   "hsl(76.70886075949366, 57.66423357664233%, 73.13725490196077%)",
//   "hsl(250.79999999999998, 80.64516129032259%, 36.470588235294116%)",
//   "#26d935",
//   "hsl(66.11464968152866, 71.04072398190046%, 43.333333333333336%)",
//   "hsl(196.85714285714286, 92.10526315789474%, 44.705882352941174%)",
//   "hsl(327.14285714285717, 82.89473684210526%, 29.80392156862745%)",
//   "hsl(172.2077922077922, 92.77108433734938%, 16.274509803921568%)",
//   "hsl(276.1038961038961, 95.85062240663899%, 47.254901960784316%)",
//   "#038f16",
//   "hsl(133.04347826086956, 89.94413407821229%, 35.09803921568627%)",
//   "hsl(66, 81.5217391304348%, 36.07843137254902%)",
//   "hsl(169.99999999999997, 79.24528301886792%, 41.568627450980394%)",
// ];

const UsersDashboard = ({ profile, error }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [date, setDate] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const timer = React.useRef();
  // const [chartData, setChartData] = React.useState([]);
  // const [notifs, setNotifs] = React.useState([]);
  const { notifications } = useSelector((state) => state.notification);


  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  // const {
  //   annualTotal,
  //   loadingAnnual,
  //   loadingMonthly,
  //   loadingSupplementary,
  //   monthlyTotal,
  //   supplementaryTotal,
  // } = useSelector((state) => state.analytics);
  // const { count, loadingCount } = useSelector((state) => state.count);

  const welcome = localStorage.getItem("welcome");

  React.useEffect(() => {
    const year = moment().format("YYYY");
    if (year) {
      dispatch(getTotalAnnualBudget(year));
    }
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  React.useEffect(() => {
    const month = moment().format("MMMM");
    if (month) {
      dispatch(getTotalMonthlyBudget(month));
    }
  }, [dispatch]);

  React.useEffect(() => {
    const month = moment().format("MMMM");
    if (month) {
      dispatch(getTotalSupplementaryBudget(month));
    }
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCount());
  }, [dispatch]);

  // React.useEffect(() => {
  //   const data =
  //     count &&
  //     count.data &&
  //     count.data.map((item) => {
  //       const id = item.div && item.div;
  //       return {
  //         id,
  //         value: item.count,
  //         color: colors[Math.floor(Math.random() * colors.length)],
  //         label: item.div,
  //       };
  //     });
  //   setChartData(data || []);
  // }, [count]);

  // console.log(chartData);

  const currentDateCallback = React.useCallback(() => {
    const currentDate = moment(new Date(), "LL").format("dddd, MMMM Do, YYYY");
    setDate(currentDate);
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();
    currentDateCallback();
    return () => abortController.abort();
  }, [currentDateCallback]);

  const showTimeCallback = React.useCallback(() => {
    setTime(moment().format("LTS"));
    timer.current = setTimeout(showTimeCallback, 1000);
  }, []);

  React.useEffect(() => {
    showTimeCallback();
    return () => clearTimeout(timer.current);
  }, [showTimeCallback]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleNotificationRoute = (path, id) => {
    if (path) {
        history.push(path);
        setTimeout(() => {
            dispatch(deleteNotification(id));
        }, 2000);
    }
  };

  const handleDeleteNotification = (id) => {
    dispatch(deleteNotification(id));
  };

  return (
    <div className={classes.root}>
      {error && error.error && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={error && error.error ? true : false}
          severity="error"
          message={(error && error.error) || feed.message}
          horizontal="center"
          vertical="bottom"
        />
      )}
      {!welcome && profile ? <WelcomeFeedback /> : null}
      <Typography className={classes.helloText}>
        Hello, {profile && profile.userName}
      </Typography>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="overline" className={classes.analytics}>
            Dashboard
          </Typography>
        </Grid>
        <Grid item>
          <Paper
            style={{ display: "flex", width: 318 }}
            component="div"
            elevation={1}
            variant="outlined"
            square
          >
            <span className={classes.date}>{date}</span>
            <Divider orientation="vertical" flexItem />

            <span className={classes.time}>{time}</span>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        // className={classes.gridContainer2}
        justify="space-between"
        // alignItems="stretch"
        alignItems="flex-start"
        spacing={2}
        // component={Paper}
      >

        <Grid item xs={12} md={8}>
          <Grid container justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} md={6} sm={6}>
              <div>
                <CardContent className={[classes.cardContentOverflow, "card employee-welcome-card"]}>
                  <div className="card-body">
                      <div className="welcome-info">
                          <div className="welcome-content">
                              <h5>Welcome back, {profile && profile.userName}</h5>
                              <p><span>{profile && profile.jobTitle && profile.jobTitle?.name}</span></p>
                          </div>
                          <div className="welcome-img">
                              <img src={profile && profile.photo ? profile.photo : avatar} className="img-fluid" alt="User" />
                          </div>
                      </div>
                      <div className="welcome-btn">
                          <Button variant="contained" 
                            onClick={() => history.push("/account")} className={classes.btn}>View Profile</Button>
                      </div>
                  </div>
                </CardContent>
              </div>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <Card>
                <CardContent className={[classes.cardContentOverflow]}>
                  <div className="logobox">
                      <img src={russelsmithgrp} className="" alt="logo" width={40} height={40} />
                  </div>
                  <div className="namebox">
                      {/* <h4>{(user && user.organization) ? `${user && user.organization?.name}` : 'loading...'}</h4> */}
                  </div>
                  <div className="detailbox">
                      <p><span className="title">RusselSmith Nigeria Limited</span> 
                          <span className="det"></span>
                      </p>
                      <p><span className="det">18 Adeola Hopewell Street, Victoria Island, Lagos</span></p>
                  </div>
                </CardContent>
              </Card>
            </Grid>

          </Grid>


          <Grid container justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <div className="wrapper" style={{ marginTop: 30 }}>
                <Typography className={classes.overview} gutterBottom>
                  Quick Links {" "}
                </Typography>
                <CardContent className={classes.cardContent2}>
                <ButtonGroup>
                  <Button
                    color="primary"
                    style={{ backgroundColor:"#FF7E00", borderColor:'#FF7E00', minWidth: '200px' }}
                    variant="contained"
                    endIcon={<Icon className="fas fa-chevron-right" />}
                    className={classes.btn}
                    href="https://russelsmithlearninghub.talentlms.com/dashboard"
                    target="_blank"
                  >
                    Talent LMS
                  </Button>

                  <Button
                    color="primary"
                    style={{ marginLeft: 15 }}
                    variant="contained"
                    endIcon={<Icon className="fas fa-chevron-right" />}
                    className={classes.btn}
                    onClick={() => history.push("/inventory/requisition")}
                  >
                    Inventory Req.
                  </Button>

                  <Button
                    color="secondary"
                    style={{ marginLeft: 15 }}
                    variant="contained"
                    endIcon={<Icon className="fas fa-chevron-right" />}
                    className={classes.btn}
                    onClick={() => history.push("/purchasing/requisition")}
                  >
                    Purchase Req.
                  </Button>

                  <Button
                    color="primary"
                    style={{ marginLeft: 15, backgroundColor:"#093", borderColor:'#093', minWidth: '200px' }}
                    variant="contained"
                    endIcon={<Icon className="fas fa-chevron-right" />}
                    className={classes.btn}
                    href="https://people.zoho.com/russelsmithnigerialimited/zp#home/dashboard"
                    target="_blank"
                  >
                    Zoho People
                  </Button>

                  <Button
                    color="primary"
                    style={{ marginLeft: 15 }}
                    variant="contained"
                    endIcon={<Icon className="fas fa-chevron-right" />}
                    className={classes.btn}
                    href="https://russelsmith.sharepoint.com/pop/User%20Guides/Forms/AllItems.aspx"
                    target="_blank"
                  >
                    User Guides
                  </Button>
                  
                </ButtonGroup>
                </CardContent>
                
              </div>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent className={classes.cardContent}>
              <div className="card flex-fill task_statistics mt-1">
                <div className="card-body">

                    <div className="statistic-header">
                        <h4>Notifications</h4>
                        <div className="important-notification">
                            <Button onClick={() => history.push("/notifications")}>
                                View All <span style={{marginLeft:7}}><i className="fas fa-chevron-right" /></span>
                            </Button>
                        </div>
                    </div>

                    <div className="employee-noti-content notification_pad">
                        {notifications && notifications.length > 0 ? 
                            <ul className="employee-notification-list">
                                {notifications && notifications.map((notif, i) => (
                                <div className="employee-notification-grid" key={i} style={{ width:"100%" }} >
                                    <div className="employee-notification-icon">
                                        <span className="badge-soft-danger rounded-circle">{i + 1}</span>
                                    </div>
                                    <div className="employee-notification-content">
                                        <h6 
                                          onClick={() => handleNotificationRoute(notif.path, notif._id)}
                                        >{notif?.title && notif?.title} 
                                          <IconButton
                                          color="secondary"
                                          onClick={() => handleDeleteNotification(notif._id)}
                                        >
                                          <DeleteIcon color="secondary" />
                                        </IconButton>
                                        </h6>
                                        
                                        <li>{notif?.body && notif?.body}</li>
                                        <li>{notif?.createdAt && moment(notif?.createdAt).fromNow()}</li>
                                    </div>
                                </div>
                                ))}
                            </ul>
                        : 
                        <div className="employee-noti-content notification_page_pad">
                          <img
                              src="/img/high-five-1.svg"
                              alt="ship"
                              className={classes.ship}
                          />
                          <Typography variant="overline" className={classes.zeroNotify}>
                              You Don't Have Any Notification
                          </Typography>
                      </div> }
                    </div>
                </div>
            </div>

              {/* <Typography variant="overline" className={classes.cardTitle}>
                Active Users
              </Typography>
              {loadingCount ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  // animation="wave"
                  style={{
                    background: "#ffc1071f",
                    padding: 10,
                    borderRadius: 3,
                  }}
                />
              ) : (
                <PieChart data={chartData} style={{ overflow: "hidden" }} />
              )}

              <Typography variant="overline" className={classes.cardTitle}>
                Total users:{" "}
                <b>
                  {loadingCount ? (
                    <CircularProgress size={16} style={{ marginBottom: -3 }} />
                  ) : (
                    count && count.total
                  )}
                </b>
              </Typography> */}
            </CardContent>
          </Card>
        </Grid>
        
        {/* <Grid item xs={12} md={8}>
          <Card>
            <CardContent className={classes.cardContent2}>
              <Typography
                variant="overline"
                className={classes.cardTitle2}
                gutterBottom
              >
                Explore
              </Typography>
              <Typography
                gutterBottom
                className={classes.cardTitle3}
                variant="overline"
              >
                <q>
                  The shorter way to do many things is to only do one thing at a
                  time.
                </q>
                <span className={classes.left}>&mdash; Mozart</span>
              </Typography>


            </CardContent>
          </Card>
        </Grid> */}
        
      </Grid>

      {/* <Grid
        container
        className={classes.gridContainer}
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <Card variant="outlined" square>
            <CardContent>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  {loadingAnnual ? (
                    <div>
                      <CircularProgress size={20} />
                    </div>
                  ) : (
                    <Typography className={classes.currency}>
                      {formatCurrency(annualTotal || 0, "en-NG", "NGN")}
                    </Typography>
                  )}

                  <Typography variant="overline" className={classes.infoText}>
                    Annual Budgets
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar
                    src="/img/money-bag.svg"
                    alt="Money Bag"
                    variant="square"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" square>
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  {loadingMonthly ? (
                    <div>
                      <CircularProgress size={20} />
                    </div>
                  ) : (
                    <Typography className={classes.currency}>
                      {formatCurrency(monthlyTotal || 0, "en-NG", "NGN")}
                    </Typography>
                  )}

                  <Typography variant="overline" className={classes.infoText}>
                    Monthly Budgets
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar src="/img/money.svg" alt="Money" variant="square" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" square>
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  {loadingSupplementary ? (
                    <div>
                      <CircularProgress size={20} />
                    </div>
                  ) : (
                    <Typography className={classes.currency}>
                      {formatCurrency(supplementaryTotal || 0, "en-NG", "NGN")}
                    </Typography>
                  )}

                  <Typography variant="overline" className={classes.infoText}>
                    Supplementary Budgets
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar src="/img/dollar.svg" alt="Dollar" variant="square" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}

      {/* <div className="wrapper" style={{ marginTop: 30 }}>
        <Typography className={classes.overview} gutterBottom>
          Purchase Requisition{" "}
        </Typography>

        <PurchaseTable />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
  error: state.user.authError,
});

export default connect(mapStateToProps, null)(UsersDashboard);
