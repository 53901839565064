module.exports = {
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_USER: "GET_USER",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  RESEND_PASSWORD_LINK: "RESEND_PASSWORD_LINK",
  VERIFY_USER: "VERIFY_USER",
  AUTH_ERROR: "AUTH_ERROR",
  GET_PROFILE: "GET_PROFILE",
  LOGOUT: "LOGOUT",
  CREATE_USER_ACCOUNT: "CREATE_USER_ACCOUNT",
  GET_USER_BY_TOKEN: "GET_USER_BY_TOKEN",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  UPLOAD_USER_PHOTO: "UPLOAD_USER_PHOTO",
  UPLOAD_USER_PHOTO_ERROR: "UPLOAD_USER_PHOTO_ERROR",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
  UPDATE_USER_PASSWORD_REQUEST: "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_FAIL: "UPDATE_USER_PASSWORD_FAIL",
  UPDATE_TWO_FACTOR: "UPDATE_TWO_FACTOR",
  USERS_ERROR: "USERS_ERROR",

  UPLOAD_SIGNATURE: "UPLOAD_SIGNATURE",
  UPLOAD_SIGNATURE_REQUEST: "UPLOAD_SIGNATURE_REQUEST",
  UPLOAD_SIGNATURE_FAIL: "UPLOAD_SIGNATURE_FAIL",

  REMOVE_SIGNATURE: "REMOVE_SIGNATURE",
  REMOVE_SIGNATURE_REQUEST: "REMOVE_SIGNATURE_REQUEST",
  REMOVE_SIGNATURE_FAIL: "REMOVE_SIGNATURE_FAIL",

  REMOVE_PROFILE_PICTURE: "REMOVE_PROFILE_PICTURE",
  REMOVE_PROFILE_PICTURE_REQUEST: "REMOVE_PROFILE_PICTURE_REQUEST",
  REMOVE_PROFILE_PICTURE_FAIL: "REMOVE_PROFILE_PICTURE_FAIL",

  GET_NOTIFICATION: "GET_NOTIFICATION",
  GET_NOTIFICATION_REQUEST: "GET_NOTIFICATION_REQUEST",
  GET_NOTIFICATION_FAIL: "GET_NOTIFICATION_FAIL",

  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  DELETE_NOTIFICATION_REQUEST: "DELETE_NOTIFICATION_REQUEST",
  DELETE_NOTIFICATION_FAIL: "DELETE_NOTIFICATION_FAIL",

  ENABLE_2FA_REQUEST: "ENABLE_2FA_REQUEST",
  ENABLE_2FA: "ENABLE_2FA",
  ENABLE_2FA_FAIL: "ENABLE_2FA_FAIL",

  BULK_RESEND_PASSWORD_LINK_REQUEST: "BULK_RESEND_PASSWORD_LINK_REQUEST",
  BULK_RESEND_PASSWORD_LINK: "BULK_RESEND_PASSWORD_LINK",
  BULK_RESEND_PASSWORD_LINK_FAIL: "BULK_RESEND_PASSWORD_LINK_FAIL",

  DISABLE_2FA_REQUEST: "DISABLE_2FA_REQUEST",
  DISABLE_2FA: "DISABLE_2FA",
  DISABLE_2FA_FAIL: "DISABLE_2FA_FAIL",

  SET_PASSWORD_BY_TOKEN: "SET_PASSWORD_BY_TOKEN",

  GET_RESET_USER_DATA_BY_TOKEN: "GET_RESET_USER_DATA_BY_TOKEN",
  RESET_PASSWORD: "RESET_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  VENDOR_REQUEST_LINK: "VENDOR_REQUEST_LINK",

  ERROR: "ERROR",

  CREATE_JOB_TITLE: "CREATE_JOB_TITLE",
  GET_JOB_TITLES: "GET_JOB_TITLES",
  GET_JOB_TITLE_ERROR: "GET_JOB_TITLE_ERROR",
  GET_JOB_TITLE_BY_ID: "GET_JOB_TITLE_BY_ID",
  UPDATE_JOB_TITLE: "UPDATE_JOB_TITLE",
  DELETE_JOB_TITLE: "DELETE_JOB_TITLE",
  JOB_TITLE_ERROR: "JOB_TITLE_ERROR",

  GET_DIVISIONS: "GET_DIVISIONS",
  GET_DIVISIONS_REQUEST: "GET_DIVISIONS_REQUEST",
  GET_DIVISIONS_FAIL: "GET_DIVISIONS_FAIL",

  GET_DIVISION_BY_ID: "GET_DIVISION_BY_ID",
  GET_DIVISION_BY_ID_REQUEST: "GET_DIVISION_BY_ID_REQUEST",
  GET_DIVISION_BY_ID_FAIL: "GET_DIVISION_BY_ID_FAIL",
  UPDATE_DIVISION: "UPDATE_DIVISION",
  UPDATE_DIVISION_REQUEST: "UPDATE_DIVISION_REQUEST",
  UPDATE_DIVISION_FAIL: "UPDATE_DIVISION_FAIL",
  CREATE_DIVISION: "CREATE_DIVISION",
  CREATE_DIVISION_REQUEST: "CREATE_DIVISION_REQUEST",
  CREATE_DIVISION_FAIL: "CREATE_DIVISION_FAIL",
  DELETE_DIVISION: "DELETE_DIVISION",
  DELETE_DIVISION_FAIL: "DELETE_DIVISION_FAIL",
  DELETE_DIVISION_REQUEST: "DELETE_DIVISION_REQUEST",

  GET_DIVISIONS_BY_SERVICE: "GET_DIVISIONS_BY_SERVICE",
  GET_DIVISIONS_BY_SERVICE_REQUEST: "GET_DIVISIONS_BY_SERVICE_REQUEST",
  GET_DIVISIONS_BY_SERVICE_FAIL: "GET_DIVISIONS_BY_SERVICE_FAIL",

  DIVISION_ERROR: "DIVISION_ERROR",

  GET_GROUPS_BY_MODULE: "GET_GROUPS_BY_MODULE",
  CREATE_GROUP: "CREATE_GROUP",
  GET_ALL_GROUPS: "GET_ALL_GROUPS",
  DELETE_GROUP: "DELETE_GROUP",

  UPDATE_GROUP: "UPDATE_GROUP",
  GET_GROUP: "GET_GROUP",

  GET_ALL_ACCESS_RIGHTS: "GET_ALL_ACCESS_RIGHTS",
  CREATE_ACCESS_RIGHT: "CREATE_ACCESS_RIGHT",
  DELETE_SINGLE_ACCESS_RIGHT: "DELETE_SINGLE_ACCESS_RIGHT",

  GET_ALL_BUDGET_HEADS: "GET_ALL_BUDGET_HEADS",
  CREATE_BUDGET_HEAD: "CREATE_BUDGET_HEAD",
  GET_BUDGET_HEAD_BY_ID: "GET_BUDGET_HEAD_BY_ID",
  UPDATE_BUDGET_HEAD: "UPDATE_BUDGET_HEAD",
  DELETE_BUDGET_HEAD: "DELETE_BUDGET_HEAD",

  GET_ALL_BUDGET_SUB_HEADS: "GET_ALL_BUDGET_SUB_HEADS",
  GET_ALL_BUDGET_SUB_HEADS_FAIL: "GET_ALL_BUDGET_SUB_HEADS_FAIL",
  GET_ALL_BUDGET_SUB_HEADS_REQUEST: "GET_ALL_BUDGET_SUB_HEADS_REQUEST",

  CREATE_BUDGET_SUB_HEAD: "CREATE_BUDGET_SUB_HEAD",
  CREATE_BUDGET_SUB_HEAD_REQUEST: "CREATE_BUDGET_SUB_HEAD_REQUEST",
  CREATE_BUDGET_SUB_HEAD_FAIL: "CREATE_BUDGET_SUB_HEAD_FAIL",

  GET_BUDGET_SUB_HEAD_BY_ID: "GET_BUDGET_SUB_HEAD_BY_ID",

  GET_BUDGET_SUB_HEAD_BY_HEAD: "GET_BUDGET_SUB_HEAD_BY_HEAD",
  UPDATE_BUDGET_SUB_HEAD: "UPDATE_BUDGET_SUB_HEAD",
  DELETE_BUDGET_SUB_HEAD: "DELETE_BUDGET_SUB_HEAD",

  GET_ALL_BUDGET_ITEMS: "GET_ALL_BUDGET_ITEMS",
  GET_ALL_BUDGET_ITEMS_REQUEST: "GET_ALL_BUDGET_ITEMS_REQUEST",
  GET_ALL_BUDGET_ITEMS_FAIL: "GET_ALL_BUDGET_ITEMS_FAIL",

  CREATE_BUDGET_ITEM: "CREATE_BUDGET_ITEM",
  GET_BUDGET_ITEM_BY_ID: "GET_BUDGET_ITEM_BY_ID",
  GET_BUDGET_ITEM_BY_SUBHEAD_AND_DIVISION:
    "GET_BUDGET_ITEM_BY_SUBHEAD_AND_DIVISION",
  UPDATE_BUDGET_ITEM: "UPDATE_BUDGET_ITEM",
  DELETE_BUDGET_ITEM: "DELETE_BUDGET_ITEM",
  BUDGET_ITEM_ERROR: "BUDGET_ITEM_ERROR",

  CREATE_ANNUAL_BUDGET: "CREATE_ANNUAL_BUDGET",
  GET_ANNUAL_BUDGETS: "GET_ANNUAL_BUDGETS",
  GET_ALL_ANNUAL_BUDGETS: "GET_ALL_ANNUAL_BUDGETS",
  GET_ALL_ANNUAL_BUGDETS_REVIEWS: "GET_ALL_ANNUAL_BUGDETS_REVIEWS",
  GET_ALL_ANNUAL_BUGDETS_APPROVALS: "GET_ALL_ANNUAL_BUGDETS_APPROVALS",
  ANNUAL_BUDGETS_ERROR: "ANNUAL_BUDGETS_ERROR",
  ANNUAL_BUDGET_ERROR: "ANNUAL_BUDGET_ERROR",
  GET_ALL_ANNUAL_BUDGET_LINES: "GET_ALL_ANNUAL_BUDGET_LINES",

  REVIEW_ALL_ANNUAL_BUDGET: "REVIEW_ALL_ANNUAL_BUDGET",
  APPROVE_ALL_ANNUAL_BUDGET: "APPROVE_ALL_ANNUAL_BUDGET",

  APPROVE_SINGLE_ANNUAL_BUDGET: "APPROVE_SINGLE_ANNUAL_BUDGET",
  REQUEST_ANNUAL_BUDGET_CLARIFICATION: "REQUEST_ANNUAL_BUDGET_CLARIFICATION",
  REQUEST_ANNUAL_BUDGET_MODIFICATION: "REQUEST_ANNUAL_BUDGET_MODIFICATION",
  CLARIFY_ANNUAL_BUDGET: "CLARIFY_ANNUAL_BUDGET",
  MODIFY_ANNUAL_BUDGET: "MODIFY_ANNUAL_BUDGET",
  SUBMIT_REJECTED_ANNUAL_BUDGET: "SUBMIT_REJECTED_ANNUAL_BUDGET",

  GET_ANNUAL_BUDGET_BY_ID: "GET_ANNUAL_BUDGET_BY_ID",
  GET_ANNUAL_BUDGET_LINE_BY_ID: "GET_ANNUAL_BUDGET_LINE_BY_ID",
  SUBMIT_ANNUAL_BUDGET: "SUBMIT_ANNUAL_BUDGET",
  UPDATE_ANNUAL_BUDGET: "UPDATE_ANNUAL_BUDGET",
  CONFIRM_ANNUAL_BUDGET: "CONFIRM_ANNUAL_BUDGET",

  DECLINE_ANNUAL_BUDGET: "DECLINE_ANNUAL_BUDGET",
  APPROVE_ANNUAL_BUDGET: "APPROVE_ANNUAL_BUDGET",
  REJECT_ANNUAL_BUDGET: "REJECT_ANNUAL_BUDGET",
  CREATE_ANNUAL_BUDGET_LINE: "CREATE_ANNUAL_BUDGET_LINE",
  UPDATE_ANNUAL_BUDGET_LINE: "UPDATE_ANNUAL_BUDGET_LINE",
  DELETE_ANNUAL_BUDGET_LINE: "DELETE_ANNUAL_BUDGET_LINE",
  ANNUAL_BUDGET_LINE_ERROR: "ANNUAL_BUDGET_LINE_ERROR",
  GET_ALL_ANNUAL_BUDGET_LINE_BY_BUDGET_ID:
    "GET_ALL_ANNUAL_BUDGET_LINE_BY_BUDGET_ID",

  CREATE_MONTHLY_BUDGET: "CREATE_MONTHLY_BUDGET",
  GET_MONTHLY_BUDGETS: "GET_MONTHLY_BUDGETS",
  GET_ALL_MONTHLY_BUDGETS: "GET_ALL_MONTHLY_BUDGETS",
  MONTHLY_BUDGETS_ERROR: "MONTHLY_BUDGETS_ERROR",

  GET_BUDGET_UTILIZATIONS: "GET_BUDGET_UTILIZATIONS",
  BUDGET_UTILIZATIONS_ERROR: "BUDGET_UTILIZATIONS_ERROR",

  CREATE_MONTHLY_BUDGET_LINE: "CREATE_MONTHLY_BUDGET_LINE",
  UPDATE_MONTHLY_BUDGET_LINE: "UPDATE_MONTHLY_BUDGET_LINE",
  DELETE_MONTHLY_BUDGET_LINE: "DELETE_MONTHLY_BUDGET_LINE",
  MONTHLY_BUDGET_LINE_ERROR: "MONTHLY_BUDGET_LINE_ERROR",

  GET_ALL_MONTHLY_BUDGET_LINES: "GET_ALL_MONTHLY_BUDGET_LINES",
  GET_ALL_MONTHLY_BUDGET_LINE_BY_BUDGET_ID:
    "GET_ALL_MONTHLY_BUDGET_LINE_BY_BUDGET_ID",

  GET_MONTHLY_BUDGET_BY_ID: "GET_MONTHLY_BUDGET_BY_ID",

  GET_MONTHLY_BUDGET_ITEMS_BY_SUBHEAD: "GET_MONTHLY_BUDGET_ITEMS_BY_SUBHEAD",
  GET_MONTHLY_BUDGET_ITEMS_BY_DIVISION: "GET_MONTHLY_BUDGET_ITEMS_BY_DIVISION",
  GET_ONE_MONTHLY_BUDGET_ITEM : "GET_ONE_MONTHLY_BUDGET_ITEM",
  GET_MONTHLY_BUDGET_LINE_BY_ID: "GET_MONTHLY_BUDGET_LINE_BY_ID",
  SUBMIT_MONTHLY_BUDGET: "SUBMIT_MONTHLY_BUDGET",

  CLARIFY_MONTHLY_BUDGET: "CLARIFY_MONTHLY_BUDGET",
  MODIFY_MONTHLY_BUDGET: "MODIFY_MONTHLY_BUDGET",
  SUBMIT_REJECTED_MONTHLY_BUDGET: "SUBMIT_REJECTED_MONTHLY_BUDGET",

  CONFIRM_MONTHLY_BUDGET: "CONFIRM_MONTHLY_BUDGET",
  DECLINE_MONTHLY_BUDGET: "DECLINE_MONTHLY_BUDGET",
  APPROVE_MONTHLY_BUDGET: "APPROVE_MONTHLY_BUDGET",
  REJECT_MONTHLY_BUDGET: "REJECT_MONTHLY_BUDGET",

  REVIEW_ALL_MONTHLY_BUDGET: "REVIEW_ALL_MONTHLY_BUDGET",
  APPROVE_ALL_MONTHLY_BUDGET: "APPROVE_ALL_MONTHLY_BUDGET",
  POSTPONE_MONTHLY_BUDGET: "POSTPONE_MONTHLY_BUDGET",

  REQUEST_MONTHLY_BUDGET_CLARIFICATION: "REQUEST_MONTHLY_BUDGET_CLARIFICATION",
  REQUEST_MONTHLY_BUDGET_MODIFICATION: "REQUEST_MONTHLY_BUDGET_MODIFICATION",

  VENDOR_REGISTER: "VENDOR_REGISTER",
  UPDATE_VENDOR_INFO: "UPDATE_VENDOR_INFO",
  GET_VENDOR_INFO_BY_TOKEN: "GET_VENDOR_INFO_BY_TOKEN",
  GET_VENDOR_PROFILE: "GET_VENDOR_PROFILE",
  UPLOAD_VENDOR_PHOTO: "UPLOAD_VENDOR_PHOTO",
  UPDATE_VENDOR_PROFILE: "UPDATE_VENDOR_PROFILE",
  UPLOAD_VENDOR_DOCUMENT: "UPLOAD_VENDOR_DOCUMENT",
  UPDATE_VENDOR_PASSWORD: "UPDATE_VENDOR_PASSWORD",
  GET_ALL_VENDORS_REQUEST: "GET_ALL_VENDORS_REQUEST",
  GET_ALL_VENDORS: "GET_ALL_VENDORS",
  GET_ALL_VENDORS_FAIL: "GET_ALL_VENDORS_FAIL",
  GET_VENDOR_BY_ID: "GET_VENDOR_BY_ID",
  APPROVE_VENDOR: "APPROVE_VENDOR",
  REJECT_VENDOR: "REJECT_VENDOR",
  BLACKLIST_VENDOR: "BLACKLIST_VENDOR",
  REACTIVATE_VENDOR: "REACTIVATE_VENDOR",
  CREATE_VENDOR: "CREATE_VENDOR",
  VENDOR_ERROR: "VENDOR_ERROR",

  CREATE_PRODUCT: "CREATE_PRODUCT",
  GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
  GET_PRODUCT_BY_ID: "GET_PRODUCT_BY_ID",
  UPDATE_PRODUCT_BY_ID: "UPDATE_PRODUCT_BY_ID",
  DELETE_PRODUCT_BY_ID: "DELETE_PRODUCT_BY_ID",
  DELETE_VENDOR_BY_ID: "DELETE_VENDOR_BY_ID",
  GET_PRODUCTS_CATEGORY: "GET_PRODUCTS_CATEGORY",
  GET_SERVICES_CATEGORY: "GET_SERVICES_CATEGORY",

  GET_CURRENCY_LISTS: "GET_CURRENCY_LISTS",
  GET_ALL_CURRENCIES: "GET_ALL_CURRENCIES",
  GET_CURRENCY: "GET_CURRENCY",
  CREATE_CURRENCY: "CREATE_CURRENCY",
  UPDATE_CURRENCY: "UPDATE_CURRENCY",
  DELETE_CURRENCY: "DELETE_CURRENCY",
  CURRENCY_ERROR: "CURRENCY_ERROR",

  CREATE_COST_CODE: "CREATE_COST_CODE",
  GET_ALL_COST_CODES: "GET_ALL_COST_CODES",
  GET_COST_CODE: "GET_COST_CODE",
  GET_COST_CODES_BY_TYPE: "GET_COST_CODES_BY_TYPE",
  UPDATE_COST_CODE: "UPDATE_COST_CODE",
  DELETE_COST_CODE: "DELETE_COST_CODE",
  COST_CODE_ERROR: "COST_CODE_ERROR",

  CREATE_SEQUENCE: "CREATE_SEQUENCE",
  UPDATE_SEQUENCE: "UPDATE_SEQUENCE",
  GET_ALL_SEQUENCES: "GET_ALL_SEQUENCES",
  GET_SEQUENCE: "GET_SEQUENCE",
  DELETE_SEQUENCE: "DELETE_SEQUENCE",
  SEQUENCE_ERROR: "SEQUENCE_ERROR",

  CREATE_MODULES: "CREATE_MODULES",
  UPDATE_MODULES: "UPDATE_MODULES",
  GET_ALL_MODULES: "GET_ALL_MODULES",
  GET_MODULE: "GET_MODULE",
  DELETE_MODULE: "DELETE_MODULE",
  MODULE_ERROR: "MODULE_ERROR",

  CREATE_SUBMODULES: "CREATE_SUBMODULES",
  UPDATE_SUBMODULE: "UPDATE_SUBMODULE",
  GET_ALL_SUBMODULES: "GET_ALL_SUBMODULES",
  GET_ALL_MODULES_AND_SUBMODULES: "GET_ALL_MODULES_AND_SUBMODULES",
  GET_SUBMODULE: "GET_SUBMODULE",
  DELETE_SUBMODULE: "DELETE_SUBMODULE",
  SUBMODULE_ERROR: "SUBMODULE_ERROR",

  CREATE_CASH_ADVANCE: "CREATE_CASH_ADVANCE",
  SUBMIT_CASH_ADVANCE: "SUBMIT_CASH_ADVANCE",
  GET_CASH_ADVANCE_BY_ID: "GET_CASH_ADVANCE_BY_ID",
  UPDATE_CASH_ADVANCE: "UPDATE_CASH_ADVANCE",
  DELETE_CASH_ADVANCE: "DELETE_CASH_ADVANCE",
  CASH_ADVANCE_ERROR: "CASH_ADVANCE_ERROR",
  PAY_CASH_ADVANCE: "PAY_CASH_ADVANCE",
  APPROVE_CASH_ADVANCE: "APPROVE_CASH_ADVANCE",
  REJECT_CASH_ADVANCE: "REJECT_CASH_ADVANCE",

  REVIEW_CASH_ADVANCE: "REVIEW_CASH_ADVANCE",
  DECLINE_CASH_ADVANCE: "DECLINE_CASH_ADVANCE",
  HOD_CONFIRM_CASH_RETIREMENT: "HOD_CONFIRM_CASH_RETIREMENT",
  HOD_DECLINE_CASH_RETIREMENT: "HOD_DECLINE_CASH_RETIREMENT",
  FIN_ACCEPT_CASH_RETIREMENT: "FIN_ACCEPT_CASH_RETIREMENT",
  FIN_REJECT_CASH_RETIREMENT: "FIN_REJECT_CASH_RETIREMENT",

  RETIRE_CASH_ADVANCE: "RETIRE_CASH_ADVANCE",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  GET_ALL_CASH_ADVANCES: "GET_ALL_CASH_ADVANCES",
  GET_ALL_CASH_ADVANCES_AWAITING_FINANCE_PAY_OR_CEO_APPROVAL:
    "GET_ALL_CASH_ADVANCES_AWAITING_FINANCE_PAY_OR_CEO_APPROVAL",
  GET_ALL_PAID_AND_RETIRED_ADVANCES: "GET_ALL_PAID_AND_RETIRED_ADVANCES",

  CREATE_CASH_RECOVERY: "CREATE_CASH_RECOVERY",
  DELETE_CASH_RECOVERY: "DELETE_CASH_RECOVERY",
  GET_ALL_CASH_RECOVERY: "GET_ALL_CASH_RECOVERY",
  GET_CASH_RECOVERY_BY_ID: "GET_CASH_RECOVERY_BY_ID",
  PPM_RECOVER_CASH: "PPM_RECOVER_CASH",
  FINANCE_ALLOW_CASH_RECOVERY: "FINANCE_ALLOW_CASH_RECOVERY",
  FINANCE_EXEMPT_CASH_RECOVERY: "FINANCE_EXEMPT_CASH_RECOVERY",
  CASH_RECOVERY_ERROR: "CASH_RECOVERY_ERROR",
  CASH_RECOVERY_LOG_ERROR: "CASH_RECOVERY_LOG_ERROR",
  GET_OUTSTANDING_TOTAL: "GET_OUTSTANDING_TOTAL",

  FINANCE_COMPLETE_RECOVERY: "FINANCE_COMPLETE_RECOVERY",
  FINANCE_COMPLETE_RECOVERY_REQUEST: "FINANCE_COMPLETE_RECOVERY_REQUEST",
  FINANCE_COMPLETE_RECOVERY_FAIL: "FINANCE_COMPLETE_RECOVERY_FAIL",

  PPM_COMPLETE_RECOVERY: "PPM_COMPLETE_RECOVERY",
  PPM_COMPLETE_RECOVERY_REQUEST: "PPM_COMPLETE_RECOVERY_REQUEST",
  PPM_COMPLETE_RECOVERY_FAIL: "PPM_COMPLETE_RECOVERY_FAIL",

  GET_ALL_CASH_RECOVERY_LOG: "GET_ALL_CASH_RECOVERY_LOG",

  CREATE_REIMBURSEMENT: "CREATE_REIMBURSEMENT",
  UPDATE_REIMBURSEMENT: "UPDATE_REIMBURSEMENT",
  DELETE_REIMBURSEMENT: "DELETE_REIMBURSEMENT",
  GET_ALL_REIMBURSEMENT: "GET_ALL_REIMBURSEMENT",
  GET_REIMBURSEMENT_BY_ID: "GET_REIMBURSEMENT_BY_ID",
  REJECT_REIMBURSEMENT: "REJECT_REIMBURSEMENT",
  SUBMIT_REIMBURSEMENT: "SUBMIT_REIMBURSEMENT",
  REIMBURSEMENT_ERROR: "REIMBURSEMENT_ERROR",
  APPROVE_REIMBURSEMENT: "APPROVE_REIMBURSEMENT",

  REVIEW_REIMBURSEMENT: "REVIEW_REIMBURSEMENT",
  DECLINE_REIMBURSEMENT: "DECLINE_REIMBURSEMENT",

  EMPLOYEE_PAY_REIMBURSEMENT: "EMPLOYEE_PAY_REIMBURSEMENT",
  FINANCE_PAY_REIMBURSEMENT: "FINANCE_PAY_REIMBURSEMENT",

  UPLOAD_DOC: "UPLOAD_DOC",

  /* PEOPLES MANAGEMENT MODULE TYPES */
  GET_ATTENDANCES: "GET_ATTENDANCES",
  GET_ATTENDANCES_REQUEST: "GET_ATTENDANCES_REQUEST",
  GET_ATTENDANCES_FAIL: "GET_ATTENDANCES_FAIL",

  GET_TODAY_ATTENDANCES: "GET_TODAY_ATTENDANCES",
  GET_TODAY_ATTENDANCES_REQUEST: "GET_TODAY_ATTENDANCES_REQUEST",
  GET_TODAY_ATTENDANCES_FAIL: "GET_TODAY_ATTENDANCES_FAIL",

  GET_QUERY_ATTENDANCES: "GET_QUERY_ATTENDANCES",
  GET_QUERY_ATTENDANCES_REQUEST: "GET_QUERY_ATTENDANCES_REQUEST",
  GET_QUERY_ATTENDANCES_FAIL: "GET_QUERY_ATTENDANCES_FAIL",
  
  GET_ATTENDANCE: "GET_ATTENDANCE",
  GET_ATTENDANCE_REQUEST: "GET_ATTENDANCE_REQUEST",
  GET_ATTENDANCE_FAIL: "GET_ATTENDANCE_FAIL",
  ATTENDANCE_RESET: "ATTENDANCE_RESET",



  CREATE_CUSTOMER: "CREATE_CUSTOMER",
  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_FAIL: "CREATE_CUSTOMER_FAIL",

  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_FAIL: "UPDATE_CUSTOMER_FAIL",

  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_FAIL: "DELETE_CUSTOMER_FAIL",

  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_FAIL: "GET_CUSTOMERS_FAIL",

  GET_CUSTOMER: "GET_CUSTOMER",
  GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMER_FAIL: "GET_CUSTOMER_FAIL",

  CREATE_SERVICE_TYPE: "CREATE_SERVICE_TYPE",
  CREATE_SERVICE_TYPE_REQUEST: "CREATE_SERVICE_TYPE_REQUEST",
  CREATE_SERVICE_TYPE_FAIL: "CREATE_SERVICE_TYPE_FAIL",

  GET_SERVICE_TYPES: "GET_SERVICE_TYPES",
  GET_SERVICE_TYPES_REQUEST: "GET_SERVICE_TYPES_REQUEST",
  GET_SERVICE_TYPES_FAIL: "GET_SERVICE_TYPES_FAIL",

  GET_SERVICE_TYPE: "GET_SERVICE_TYPE",
  GET_SERVICE_TYPE_REQUEST: "GET_SERVICE_TYPE_REQUEST",
  GET_SERVICE_TYPE_FAIL: "GET_SERVICE_TYPE_FAIL",

  GET_SERVICE_TYPE_BY_DIVISION: "GET_SERVICE_TYPE_BY_DIVISION",
  GET_SERVICE_TYPE_BY_DIVISION_REQUEST: "GET_SERVICE_TYPE_BY_DIVISION_REQUEST",
  GET_SERVICE_TYPE_BY_DIVISION_FAIL: "GET_SERVICE_TYPE_BY_DIVISION_FAIL",

  UPDATE_SERVICE_TYPE: "UPDATE_SERVICE_TYPE",
  UPDATE_SERVICE_TYPE_REQUEST: "UPDATE_SERVICE_TYPE_REQUEST",
  UPDATE_SERVICE_TYPE_FAIL: "UPDATE_SERVICE_TYPE_FAIL",

  DELETE_SERVICE_TYPE: "DELETE_SERVICE_TYPE",
  DELETE_SERVICE_TYPE_REQUEST: "DELETE_SERVICE_TYPE_REQUEST",
  DELETE_SERVICE_TYPE_FAIL: "DELETE_SERVICE_TYPE_FAIL",

  CREATE_CONTACT_PERSON: "CREATE_CONTACT_PERSON",
  CREATE_CONTACT_PERSON_REQUEST: "CREATE_CONTACT_PERSON_REQUEST",
  CREATE_CONTACT_PERSON_FAIL: "CREATE_CONTACT_PERSON_FAIL",

  UPDATE_CONTACT_PERSON: "UPDATE_CONTACT_PERSON",
  UPDATE_CONTACT_PERSON_REQUEST: "UPDATE_CONTACT_PERSON_REQUEST",
  UPDATE_CONTACT_PERSON_FAIL: "UPDATE_CONTACT_PERSON_FAIL",

  GET_CONTACT_PERSONS: "GET_CONTACT_PERSONS",
  GET_CONTACT_PERSONS_REQUEST: "GET_CONTACT_PERSONS_REQUEST",
  GET_CONTACT_PERSONS_FAIL: "GET_CONTACT_PERSONS_FAIL",

  GET_CONTACT_PERSON: "GET_CONTACT_PERSON",
  GET_CONTACT_PERSON_REQUEST: "GET_CONTACT_PERSON_REQUEST",
  GET_CONTACT_PERSON_FAIL: "GET_CONTACT_PERSON_FAIL",

  GET_CONTACT_PERSON_BY_CUSTOMER: "GET_CONTACT_PERSON_BY_CUSTOMER",
  GET_CONTACT_PERSON_BY_CUSTOMER_REQUEST:
    "GET_CONTACT_PERSON_BY_CUSTOMER_REQUEST",
  GET_CONTACT_PERSON_BY_CUSTOMER_FAIL: "GET_CONTACT_PERSON_BY_CUSTOMER_FAIL",

  DELETE_CONTACT_PERSON: "DELETE_CONTACT_PERSON",
  DELETE_CONTACT_PERSON_REQUEST: "DELETE_CONTACT_PERSON_REQUEST",
  DELETE_CONTACT_PERSON_FAIL: "DELETE_CONTACT_PERSON_FAIL",

  CREATE_CWSR_REQUEST: "CREATE_CWSR_REQUEST",
  CREATE_CWSR: "CREATE_CWSR",
  CREATE_CWSR_FAIL: "CREATE_CWSR_FAIL",

  UPDATE_CWSR_REQUEST: "UPDATE_CWSR_REQUEST",
  UPDATE_CWSR: "UPDATE_CWSR",
  UPDATE_CWSR_FAIL: "UPDATE_CWSR_FAIL",

  DELETE_CWSR_REQUEST: "DELETE_CWSR_REQUEST",
  DELETE_CWSR: "DELETE_CWSR",
  DELETE_CWSR_FAIL: "DELETE_CWSR_FAIL",

  SUBMIT_CWSR_REQUEST: "SUBMIT_CWSR_REQUEST",
  SUBMIT_CWSR: "SUBMIT_CWSR",
  SUBMIT_CWSR_FAIL: "SUBMIT_CWSR_FAIL",

  APPROVE_CWSR_REQUEST: "APPROVE_CWSR_REQUEST",
  APPROVE_CWSR: "APPROVE_CWSR",
  APPROVE_CWSR_FAIL: "APPROVE_CWSR_FAIL",

  REJECT_CWSR_REQUEST: "REJECT_CWSR_REQUEST",
  REJECT_CWSR: "REJECT_CWSR",
  REJECT_CWSR_FAIL: "REJECT_CWSR_FAIL",

  REVIEW_CWSR_REQUEST: "REVIEW_CWSR_REQUEST",
  REVIEW_CWSR: "REVIEW_CWSR",
  REVIEW_CWSR_FAIL: "REVIEW_CWSR_FAIL",

  DECLINE_CWSR_REQUEST: "DECLINE_CWSR_REQUEST",
  DECLINE_CWSR: "DECLINE_CWSR",
  DECLINE_CWSR_FAIL: "DECLINE_CWSR_FAIL",

  ACCEPT_CWSR_REQUEST: "ACCEPT_CWSR_REQUEST",
  ACCEPT_CWSR: "ACCEPT_CWSR",
  ACCEPT_CWSR_FAIL: "ACCEPT_CWSR_FAIL",

  MODIFY_CWSR_REQUEST: "MODIFY_CWSR_REQUEST",
  MODIFY_CWSR: "MODIFY_CWSR",
  MODIFY_CWSR_FAIL: "MODIFY_CWSR_FAIL",

  GET_ALL_CWSR_REQUEST: "GET_ALL_CWSR_REQUEST",
  GET_ALL_CWSR: "GET_ALL_CWSR",
  GET_ALL_CWSR_FAIL: "GET_ALL_CWSR_FAIL",

  GET_ALL_ALL_CWSR_REQUEST: "GET_ALL_ALL_CWSR_REQUEST",
  GET_ALL_ALL_CWSR: "GET_ALL_ALL_CWSR",
  GET_ALL_ALL_CWSR_FAIL: "GET_ALL_ALL_CWSR_FAIL",

  GET_CWSR_BY_ID_REQUEST: "GET_CWSR_BY_ID_REQUEST",
  GET_CWSR_BY_ID: "GET_CWSR_BY_ID",
  GET_CWSR_BY_ID_FAIL: "GET_CWSR_BY_ID_FAIL",

  UPLOAD_CWSR_DOCUMENT: "UPLOAD_CWSR_DOCUMENT",
  UPLOAD_CWSR_DOCUMENT_REQUEST: "UPLOAD_CWSR_DOCUMENT_REQUEST",
  UPLOAD_CWSR_DOCUMENT_FAIL: "UPLOAD_CWSR_DOCUMENT_FAIL",
  RESET_CWSR_DOCUMENT: "RESET_CWSR_DOCUMENT",

};
